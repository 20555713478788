import PropTypes from "prop-types";
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// _mock
// _mock

// components
import Logo from 'src/components/logo';
import Markdown from "src/components/markdown";
import IconButton from "@mui/material/IconButton";
import Iconify from "src/components/iconify";
import Socials from 'src/components/socials/socials';
import { useLocales } from "src/locales";

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'EYES',
    children: [
      { name: 'About us', href: paths.about },
      { name: 'Contact us', href: paths.contact },
      { name: 'FAQs', href: paths.faqs },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '#' },
      { name: 'Privacy Policy', href: '#' },
    ],
  },
  {
    headline: 'Contact',
    children: [{ name: 'info@eyesrealestate.com', href: '#' }],
  },
];

// ----------------------------------------------------------------------

export default function Footer({ siteSetting }) {

  const { t } = useLocales();

  const mainFooter = (
    <Box
      sx={{
        pt: 10,
        pb: 5,
        px: 20,
        bgcolor: 'primary.main',
        color: "white"
      }}
    >

      <Grid container >
        <Grid xs={12} md={4}>
          <Box
            sx={{
              typography: 'body1',
              justifyContent: {
                xs: 'center',
                md: 'space-between',
              },
              '& > :not(style) ~ :not(style)': {
                ml: 2,
              },
            }}
          >
            <Link href="/policy" underline="none" color="inherit">
              {t("policy")}
            </Link>
            <Link href="/contact-us" underline="none" color="inherit">
              {t("ContactUs")}
            </Link>
          </Box>
        </Grid>
        <Grid xs={12} md={2}>
          <Socials profile={siteSetting} />
        </Grid>
        <Grid xs={12} md={6}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'right',
            }
          }}>
          <Markdown children={siteSetting?.copyrights} />
        </Grid>
      </Grid>
    </Box>
  );

  return mainFooter;
}

Footer.propTypes = {
  siteSetting: PropTypes.object,
};