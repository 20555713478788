import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hook';
import { useLocales } from "src/locales";
import { useGetRow } from 'src/api/api';

//
import Footer from './footer';
import Header from './header';

// ----------------------------------------------------------------------

export default function MainLayout({ children }) {
  const pathname = usePathname();
  const { currentLang } = useLocales();
  const isHome = pathname === '/';
  const { row: siteSetting } = useGetRow(currentLang.value, 'web/settings');


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header siteSetting={siteSetting} />
      <Box
        component="main"
        sx={{
          mt: 10,
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 12, md: 12 },
          }),
        }}
      >
        {children}
      </Box>

      <Footer siteSetting={siteSetting} />
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};
