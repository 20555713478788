import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';


// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  album: icon('ic_album'),
  donor: icon('ic_donor'),
  community: icon('ic_community'),
  contentSetting: icon('ic_content'),
  event: icon('ic_event'),
  dashboard: icon('ic_dashboard'),
  location: icon('ic_location'),
  library: icon('ic_library'),
  menu: icon('ic_menu'),
  page: icon('ic_page'),
  setting: icon('ic_setting'),
  schedules: icon('ic_schedules'),
  user: icon('ic_user'),
  userSetting: icon('ic_user_setting'),
  webSetting: icon('ic_web_setting'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      {
        // subheader: t('overview'),
        items: [
          {
            roles: 'administrator',
            permissions: 'view_administrator',
            title: t('dashboard'), path: paths.dashboard.root,
            icon: ICONS.dashboard
          },
          {
            permissions: 'view_post',
            title: t('posts'),
            path: paths.dashboard.posts.root,
            icon: ICONS.contentSetting
          },
          // {
          //   permissions: 'view_album',
          //   title: t('albums'),
          //   path: paths.dashboard.albums.root,
          //   icon: ICONS.library
          // },
          {
            permissions: 'view_member',
            title: t('members'),
            path: paths.dashboard.members.root,
            icon: ICONS.user
          },
          {
            permissions: 'view_team',
            title: t('teams'),
            path: paths.dashboard.teams.root,
            icon: ICONS.user
          },
          {
            permissions: 'view_donor',
            title: t('donors'),
            path: paths.dashboard.donors.root,
            icon: ICONS.donor
          },
          {
            permissions: 'view_community',
            title: t('community'),
            path: paths.dashboard.communities.root,
            icon: ICONS.community
          },
          {
            permissions: 'view_page',
            title: t('pages'),
            path: paths.dashboard.pages.root,
            icon: ICONS.page
          },
          {
            permissions: 'view_people',
            title: t('users'),
            path: paths.dashboard.people.root,
            icon: ICONS.user
          },
        ],
      },
      {
        subheader: t('settings'),
        items: [
          {
            title: t('general'),
            path: paths.dashboard.settings.general.root,
            icon: ICONS.setting,
            children: [
              { title: t('settings'), path: paths.dashboard.settings.general.root },
              { title: t('menus'), path: paths.dashboard.settings.general.menus.root },
              // { title: t('careerTypes'), path: paths.dashboard.settings.general.careerTypes.root },
            ],
          },
          {
            title: t('contents'),
            path: paths.dashboard.website.root,
            icon: ICONS.contentSetting,
            children: [
              { title: t('categories'), path: paths.dashboard.website.root },
              { title: t('blocks'), path: paths.dashboard.website.blocks.root },
              { title: t('sliders'), path: paths.dashboard.website.sliders.root },
            ],
          },
          {
            title: t('people'),
            path: paths.dashboard.settings.people.root,
            icon: ICONS.userSetting,
            children: [
              { title: t('peopleTypes'), path: paths.dashboard.settings.people.root },
              { title: t('departments'), path: paths.dashboard.settings.people.departments },
              { title: t('positions'), path: paths.dashboard.settings.people.positions },
              { title: t('roles'), path: paths.dashboard.settings.people.roles },
            ],
          },
          {
            title: t('locations'),
            path: paths.dashboard.locations.root,
            icon: ICONS.location,
            children: [
              { title: t('provinces'), path: paths.dashboard.locations.root },
              { title: t('districts'), path: paths.dashboard.locations.districts.root },
              { title: t('communes'), path: paths.dashboard.locations.communes.root },
              // { title: t('villages'), path: paths.dashboard.locations.villages },
            ],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
