import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import axiosInstance, { fetcher } from 'src/utils/axios';

// Show Signle Data
export function useGetHomes(locale) {
    const URL = `/api/${locale}/web`;


    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
    const memoizedValue = useMemo(
        () => ({
            rows: data?.data || [],
            rowLoading: isLoading,
            rowError: error,
            rowValidating: isValidating,
            rowEmpty: !isLoading && !data?.data?.length,
        }),
        [data?.data, error, isLoading, isValidating]
    );

    return memoizedValue;
}
// Show Signle Data
export function useGetNavigation(locale) {
    const URL = `/api/${locale}/web/navigation`;


    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
    const memoizedValue = useMemo(
        () => ({
            rows: data?.data || [],
            rowLoading: isLoading,
            rowError: error,
            rowValidating: isValidating,
            rowEmpty: !isLoading && !data?.data?.length,
        }),
        [data?.data, error, isLoading, isValidating]
    );

    return memoizedValue;
}